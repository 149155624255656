import React, { useReducer, useCallback } from "react";
import { Root, Routes } from "react-static";
import { Link } from "@reach/router";
import styled, { ThemeProvider } from "styled-components";
import { UniProvider, Flex } from "unikit";
import {
  registerLang,
  setCurrentLocale,
  getCurrentLocale,
  Text,
  getLanguages,
} from "react-easy-i18n";

const reducer = (state, _action) => !state;

const useForceUpdate = () => {
  const [, dispatch] = useReducer(reducer, true);

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = useCallback(() => {
    dispatch(null);
  }, [dispatch]);
  return memoizedDispatch;
};

registerLang("de", {
  imprint: "Impressum",
  privacy: "Datenschutz",
  terms: "AGB",
  home: {
    hero: "Dein soziales Leben hat besseres verdient",
    desc:
      "Unsere 1-Klick-Anmeldung macht’s Dir supereinfach Dich noch heute mit Leuten in Der Nähe zu verbinden.",
  },
});

registerLang("en", {
  imprint: "Imprint",
  privacy: "Privacy",
  terms: "Terms",
  home: {
    hero: "Your social life deserves better",
    desc:
      "Our 1-click registration makes it easy for you to connect with people near you today.",
  },
});

if (typeof navigator !== "undefined") {
  var userLang = navigator.language || navigator.userLanguage;
  var langShort = userLang.split("-")[0];
  setCurrentLocale(["de", "en"].indexOf(langShort) > -1 ? langShort : "en");
} else {
  setCurrentLocale("en");
}

import { DarkTheme } from "./Theme";

import Style from "./Style";

const Main = styled.main`
  width: 100vw;
  min-height: 100vh;
`;

const Header = styled.header`
  position: absolute;
  left: 20px;
  right: 20px;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  height: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 200;
`;

const Footer = styled.footer`
  width: 100%;
  height: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5rem 0 2rem;

  a {
    color: rgba(20, 0, 0, 0.5);
    font-weight: 300;
    margin-right: 20px;
  }
`;

const Wrapper = styled.div`
  width: 90%;
  height: auto;
  margin: 0 auto;
  max-width: 1000px;
`;

const LangIcon = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 20px;
  background: #fff;
  border: 2px solid #FFF;
  margin-left: 8px;
  background: url("/images/${(props) => props.lang}.svg") no-repeat center
    center;
  background-size: cover;
  opacity: ${(props) => (props.active ? 1 : 0.6)};
`;

const StoreLogo = styled.img`
  height: 40px;
  margin-left: 5px;
  position: relative;
  z-index: 10;
`;

function App() {
  var theme = Object.assign({}, DarkTheme, {
    unikit: {
      colors: {
        primary: "#FF6B87",
      },
      globals: {
        borderRadius: 10,
        lightness: 0.95,
      },
    },
  });
  console.log({ theme, getLanguages: getLanguages() });
  const langs = getLanguages();
  const forceUpdate = useForceUpdate();
  setTimeout(() => {
    setCurrentLocale("de");
  }, 5000);
  return (
    <UniProvider theme={theme}>
      <Root>
        <Style />
        <Header>
          <Wrapper>
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <Link to="/">
                <img
                  src="/images/logo_full.svg"
                  width="auto"
                  height="34px"
                  alt=""
                />
              </Link>
              <Flex flexDirection="row" justifyContent="flex-end">
                {Object.keys(langs).map((key) => {
                  return (
                    <LangIcon
                      key={key}
                      lang={key}
                      active={getCurrentLocale() === key}
                      onClick={() => {
                        setCurrentLocale(key);
                        forceUpdate();
                      }}
                    />
                  );
                })}
              </Flex>
            </Flex>
          </Wrapper>
        </Header>
        <Main>
          {/* <Routes /> */}
          <Routes />
        </Main>
        <Footer>
          <Wrapper>
            <Flex
              flexDirection={["column", "row"]}
              justifyContent={["center", "space-between"]}
              width="100%"
            >
              <Flex
                flexDirection="row"
                justifyContent={["center", "flex-start"]}
                marginBottom={["50px", "0px"]}
              >
                <Link to="/imprint">
                  <Text text="imprint" />
                </Link>
                <Link to="/eula">
                  <Text text="terms" />
                </Link>
                <Link to="/privacy">
                  <Text text="privacy" />
                </Link>
              </Flex>
              <Flex flexDirection="row" justifyContent="flex-end">
                <a href="https://apps.apple.com/de/app/lyno/id1457198506?l=de&ls=1">
                  <StoreLogo
                    src="./images/appstore.svg"
                    alt="Apple Appstore iOS"
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.lyno.live">
                  <StoreLogo
                    src="./images/playstore.svg"
                    alt="Apple Appstore iOS"
                  />
                </a>
              </Flex>
            </Flex>
          </Wrapper>
        </Footer>
      </Root>
    </UniProvider>
  );
}

export default App;
