import { createGlobalStyle } from "styled-components";
import { Reset } from "contist-ui";

export default createGlobalStyle`
    ${Reset}

    @import url('https://fonts.googleapis.com/css?family=Roboto:300,500');

  html, body {
    font-family: 'Roboto', sans-serif;
    background-color: ${p => p.theme.colors.primaryGradient[0]};
    background: linear-gradient(90deg, ${p =>
      p.theme.colors.primaryGradient[0]}, ${p =>
  p.theme.colors.primaryGradient[1]});
    width: 100vw;
    font-size: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    @media (max-width: 44em) {
      font-size: 85%;
    }
  }

  h1 {
    text-transform: uppercase;
    letter-spacing: 0px;
  }

`;
