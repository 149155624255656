import { rgba, lighten } from "contist-ui";

export const DarkTheme = {
  colors: {
    background: "#FFF",
    primary: "#FF6B87",
    text: rgba("#275165", 0.6),
    primaryLight: lighten(0.25, "#FF6B87"),
    grey: "#275165",
    shadow: rgba("#275165", 0.1),
    primaryGradient: ["#FF6B87", "#FE8864"],
    success: "#4CAF50",
    danger: "#F44336"
  },
  breakpoints: {
    mb: 0,
    tb: 768,
    dt: 1024
  },
  globals: { wrapperWidth: "600px" }
};
